img {
    max-width:100%;
    height:auto;
}

/* .test-container {
    display:flex;
    flex-wrap: wrap;
    padding: 4rem;
} */

#service-image {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;  
    top: 50%
}

#service-text {
    text-align: left;
    align-self: center;
    
    padding: 2rem;
    font-size: 1.1rem;
}