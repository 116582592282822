* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

/* CSS variables */
:root {
  --white: white;
  --lightgrey: rgba(241, 241, 241, 0.991);
  --darkgrey: rgb(102, 99, 99);
  --black: #272d34;
  --orange: #ed7d31;
  --lightorange: rgba(252, 160, 62, 0.969);
  --blue: rgb(49, 49, 206);
}

body {
  color: var(--black);
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 28px;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold
}

ul {
  list-style: none;
}

.disc-list ul {
  list-style-type: disc;
  padding-left: 2.5rem;

}

p, 
button {
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  columns: var(--white);
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.primary-color {
  color: var(--white)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.two-column {
  display:flex;
  flex-wrap: wrap;
  padding: 1rem;
}

.row-thingy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column-centered {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  font-size: 16px;
  padding: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;

}

#column-text-left-aligned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  font-size: 16px;
}
