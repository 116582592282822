.testimonial-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    flex: 1;
    text-align: center;
    font-size: 16px;
    padding: 2.5rem 10rem 1rem;
    height: 15%;
    background-color: var--(white);
}


.carousel .slide img {
    height: 100%;  
    width: auto;
    max-width: 1000px;
}

.control-dots {
    margin: 0px 0px;
}

@media screen and (max-width:960px) {
    .testimonial-header{
        padding:1rem 2rem 1rem;
    }
   
}