.about-background {
    background-color: var(--lightgrey);
    height: 100%;
    width: 100%;
    padding: 5rem;
}

@media screen and (max-width:960px) {
    .about-background {
        padding: 2rem
    }
}

