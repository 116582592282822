header {
    width: 100%;
    height: 12vh;
}

header .navbar {
    background-color: var(--white);
    width: 100%;
    height: 12vh;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    /*position: fixed; /* Set the navbar to fixed position */
    /*top: 0; /* Position the navbar at the top of the page */
}

header .logo {
    display: flex;
    align-items: center;
}

header .logo img {
    width: 200px;
    height: auto;
}

.hamburger {
    display: none;
    position: relative;
    top: 2%;
    right: 1rem;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-self: start;
    margin: 0
}

.nav-item {
    padding: 0 1rem;

}

.nav-link {
    color: black;
    
}

@media screen and (max-width:960px) {
    .hamburger {
        display: flex;
    }
    .nav-menu {
        position: fixed;
        top: -60%;
        flex-direction: column;
        justify-content: right;
        width: 100%;
        height: 50vh;
        padding: 20vh 4vh 5vh;
        align-items: flex-end;
        text-align: right;
        transition: 0.5s;
    }
    .nav-menu.active {
        top: 5%;
    }

    .nav-item {
        margin: 0.5rem 0;
    }
} 

.info-circle {
    color: var(--black); 
    background: radial-gradient(var(--white) 50%, transparent 50%);
    padding-top: 0.2rem;
    margin: 0;
}