.banner {
    width: 100%;
    height: 6vh;
    padding: 2rem;
    margin: auto;
    background-color: black;
    font-weight: bold;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.banner-p {
    margin: 0
}

@media screen and (max-width:960px) {
    .banner {
        padding: 2.7rem 2rem 2.7rem;

    }

}