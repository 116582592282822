footer {
    padding-top: 0.5rem;
    background-color: var(--black);
    height: 20vh;
    font-family: 'Montserrat', sans-serif;
    color: var(--white)

}

.footer-navbar {
    width: 100%;
    height: 40%;
    padding: 0 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: right;
    align-items: center;
    justify-content: space-between;

}
.footer-nav-menu {
    display: flex;
    align-items: center;
    justify-self: start;
    margin: 0
}
.footer-nav-link {
    color: var(--white);

}
.footer-nav-item {
    padding: 0 1.5rem;
}

footer .copyright {
    color: var(--white);
    padding: 0.5rem;
}

.social-icons {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: left;
    align-items: center;
}
.social-icon {
   padding-right: 1rem;
}

@media screen and (max-width:960px) {
    footer {
        height: 28vh;

    }
    .copyright {
        padding: 0rem;
    }
    .footer-navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 23vh
    }
    .footer-nav-menu {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    

    .footer-nav-item {
        padding: 0.5rem;
    }

} 

