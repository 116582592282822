.contact {
    padding: 5rem;
    background-color: var(--lightgrey);

}

@media screen and (max-width:960px) {
    .contact {
        padding: 2rem
    }
}


.contact ul {
    list-style-type: disc;
    padding-left: 2.5rem;

}

.contact .mailto {
    font-weight: bold;
    color: rgb(83, 139, 236);
    cursor: pointer;
}