/*
Need to import background image
*/

.landing {
    color: var(--white);
    background-image: url('../assets/bingyune-and-company-brand-power-up-2880x1200.jpeg') ;
    background-size: cover;
    width: 100%;
    height: 450px;

}

.landing .dark-overlay {
    background-color: rgba(0, 0, 0, 0.35);
    background-size: cover;
    width: 100%;
    height: 450px;
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.landing .centered {
    
/* align-items: center;
    align-self: center; */
}

sup {
    position: relative;
    font-size: 40%;
    line-height: 0;
    vertical-align: baseline;
    top: -1.2em;
}

@media screen and (max-width:960px) {
    .landing .dark-overlay {
        padding-right: 2rem;
        padding-left: 2rem;
    }
} 
