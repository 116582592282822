/*https://css-tricks.com/books/fundamental-css-tactics/infinite-scrolling-background-image/*/


.slide-container {
    padding-top: 2rem;
    padding-bottom: 1rem;
    
    overflow: hidden; 

  }
  
.sliding-background {
    background: url('../assets/logos_vf.png') repeat-x;
    background-size: 40%;
    height: 200px;
    width: 5076px;
    animation: slide 60s linear infinite;
}


@keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1692px, 0, 0); /* The image width */
    }
}