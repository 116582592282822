.button {
    width: 13rem;
    padding: 8px 17px;
    border-radius: 4px;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    color: var(--white);
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .button:hover {
    border: 1px solid var(--lightorange);
    background-color: var(--lightorange);
  }